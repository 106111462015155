<template>
     <div class="body">
          <div class="container">
               <div class="section-header-4">Industries</div>
               <p class="x-large">
                    Our services are integrated with strategic adaptability, analytical design and factored to empower your business operations. We
                    reserve our rich experience of 25+ years to provide services that help you overcome foundational challenges of setting up a new
                    business or upgrading an existing one using the latest technology. Our support services are designed to accommodate changes in
                    business requirements and manage emerging needs of your customers.
               </p>

               <div class="industries">
                    <div class="columns">
                         <div class="column">
                              <div class=" industry ">
                                   <div class="section-header is-hidden-desktop">
                                        Healthcare
                                   </div>
                                   <router-link :to="{ name: 'HEALTHCARE' }">
                                        <img src="@/assets/images/industries/healthcare2.png" alt="" />
                                   </router-link>
                                   <div>
                                        <div>
                                             <div class="section-header is-hidden-touch">
                                                  Healthcare
                                             </div>

                                             <router-link :to="{ name: 'HEALTHCARE' }" class="knowmore">Know More</router-link>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div class="column">
                              <div class=" industry ">
                                   <div class="section-header is-hidden-desktop">
                                        Finance
                                   </div>
                                   <router-link :to="{ name: 'FINANCE' }">
                                        <img src="@/assets/images/industries/finance2.png" alt="" />
                                   </router-link>
                                   <div>
                                        <div class="section-header is-hidden-touch">
                                             Finance
                                        </div>

                                        <router-link :to="{ name: 'FINANCE' }" class="knowmore">Know More</router-link>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="columns">
                         <div class="column">
                              <div class=" industry ">
                                   <div class="section-header is-hidden-desktop">
                                        Hospitality
                                   </div>
                                   <router-link :to="{ name: 'HOSPITALITY' }">
                                        <img src="@/assets/images/industries/hospitality.jpg" alt="" />
                                   </router-link>
                                   <div>
                                        <div class="section-header  is-hidden-touch">
                                             Hospitality
                                        </div>

                                        <router-link :to="{ name: 'HOSPITALITY' }" class="knowmore">Know More</router-link>
                                   </div>
                              </div>
                         </div>
                         <div class="column">
                              <div class=" industry ">
                                   <div class="section-header is-hidden-desktop">
                                        Shopping Mall IT And Infra
                                   </div>
                                   <router-link :to="{ name: 'MALL' }">
                                        <img src="@/assets/images/industries/mall2.png" alt="" />
                                   </router-link>
                                   <div>
                                        <div class="section-header is-hidden-touch">
                                             Shopping Mall IT And Infra
                                        </div>

                                        <router-link :to="{ name: 'MALL' }" class="knowmore">Know More</router-link>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="others">
                    <div class="section-header">Other Industries</div>

                    <div class="columns">
                         <div class="column">
                              <div class="industry">
                                   <div class="section-header is-hidden-desktop">
                                        Government
                                   </div>
                                   <router-link :to="{ name: 'PROJECTS', params: { scroll: '#' + 'govt' } }">
                                        <!-- <img src="@/assets/images/industries/govt.png" alt="" /> -->

                                        <div
                                             class="square img"
                                             :style="{ 'background-image': `url(${require('@/assets/images/industries/govt.png')})` }"
                                        ></div>
                                        <div>
                                             <div class="section-header is-hidden-touch">
                                                  Government
                                             </div>
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                         <div class="column">
                              <div class="industry">
                                   <div class="section-header  is-hidden-desktop">
                                        Corporates
                                   </div>
                                   <router-link :to="{ name: 'PROJECTS', params: { scroll: '#' + 'corporate' } }">
                                        <!-- <img src="@/assets/images/industries/corp.png" alt="" /> -->

                                        <div
                                             class="square img"
                                             :style="{ 'background-image': `url(${require('@/assets/images/industries/corporate.jpg')})` }"
                                        ></div>
                                        <div>
                                             <div class="section-header is-hidden-touch">
                                                  Corporates
                                             </div>

                                             <!-- <router-link :to="{ name: 'HEALTHCARE' }" class="knowmore">Know More</router-link> -->
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                         <div class="column">
                              <div class="industry">
                                   <div class="section-header is-hidden-desktop">
                                        Airports & Shipyards
                                   </div>
                                   <router-link :to="{ name: 'PROJECTS', params: { scroll: '#' + 'airport' } }">
                                        <!-- <img src="@/assets/images/industries/airport.png" alt="" /> -->

                                        <div
                                             class="square img"
                                             :style="{ 'background-image': `url(${require('@/assets/images/industries/airport.png')})` }"
                                        ></div>
                                        <div>
                                             <div class="section-header is-hidden-touch">
                                                  Airports & Shipyards
                                             </div>

                                             <!-- <router-link :to="{ name: 'HEALTHCARE' }" class="knowmore">Know More</router-link> -->
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                    </div>
                    <div class="columns ">
                         <div class="column">
                              <div class="industry">
                                   <div class="section-header is-hidden-desktop">
                                        Hotels & Convention Centers
                                   </div>
                                   <router-link :to="{ name: 'PROJECTS', params: { scroll: '#' + 'hotels' } }">
                                        <!-- <img src="@/assets/images/industries/hotel.png" alt="" /> -->

                                        <div
                                             class="square img"
                                             :style="{ 'background-image': `url(${require('@/assets/images/industries/hotel.jpg')})` }"
                                        ></div>
                                        <div>
                                             <div class="section-header is-hidden-touch">
                                                  Hotels & Convention Centers
                                             </div>

                                             <!-- <router-link :to="{ name: 'HEALTHCARE' }" class="knowmore">Know More</router-link> -->
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                         <div class="column">
                              <div class="industry">
                                   <div class="section-header is-hidden-desktop">
                                        Education
                                   </div>
                                   <router-link :to="{ name: 'PROJECTS', params: { scroll: '#' + 'education' } }">
                                        <div
                                             class="square img"
                                             :style="{ 'background-image': `url(${require('@/assets/images/industries/education.jpg')})` }"
                                        ></div>
                                        <!--                                        
                                        <img  class="square" src="@/assets/images/industries/education.jpg" alt="" /> -->
                                        <div>
                                             <div class="section-header is-hidden-touch">
                                                  Education
                                             </div>

                                             <!-- <router-link :to="{ name: 'HEALTHCARE' }" class="knowmore">Know More</router-link> -->
                                        </div>
                                   </router-link>
                              </div>
                         </div>
                         <div class="column ">
                              <div class="more para-large vertical-align industry " style="padding-bottom:130px;">
                                   <span>
                                        <router-link
                                             :to="{ name: 'PROJECTS' }"
                                             class="knowmore "
                                             style="display:inline!important;font-size:24px!important"
                                             >View</router-link
                                        >

                                        detailed projects under various industries and the solutions provided by CTL as per their requirement.
                                   </span>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {};
</script>

<style lang="scss" scoped>
     .body {
          padding-top: 45px;
          padding-bottom: 100px;

          @media (max-width: 1023px) {
               padding-top: 20px;
               padding-bottom: 00px;
          }
     }
     .section-header-4 {
          margin-bottom: 30px;
     }
     p.x-large {
          max-width: 1200px;
          margin-bottom: 80px;
     }

     .industries {
          @media (max-width: 1023px) {
               margin-top: 100px;
          }

          .industry {
               @media (max-width: 1023px) {
                    margin-bottom: 30px;
               }
               img {
                    width: 100%;
                    height: auto;
               }
               .section-header {
                    margin-top: 10px;
                    margin-bottom: 5px;
               }
               .knowmore {
                    font-size: 22px;
               }
               margin-bottom: 30px;
          }
     }

     .others {
          margin-top: 80px;
          @media (max-width: 1023px) {
               margin-top: 80px;
          }

          .industry {
               .section-header {
                    margin-top: 10px;
                    margin-bottom: 5px;
                    font-size: 25px;
                    color: #6b6c66 !important;
               }
               .knowmore {
                    font-size: 21px;
                    cursor: pointer !important;
               }
               margin-bottom: 30px;

               img {
                    width: 100%;
                    height: auto;
               }
          }
     }

     .industry {
          img,
          .img {
               transition: filter 0.3s;
               filter: saturate(95%) brightness(95%);
          }
     }

     .industry:hover {
          img,
          .img {
               filter: saturate(120%) brightness(100%);
          }
     }

     .square {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
     }
</style>
